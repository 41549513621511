import React, { useRef } from 'react'
import type { FC } from 'react'
import type { PageProps } from 'gatsby'
import EventPageView from 'src/components/common/EventPageView'
import IntroductionText from 'src/components/cupom-de-desconto/IntroductionText'
import Coupons from 'src/components/cupom-de-desconto/Coupons'
import type { CupomDeDescontoPageQueryQuery } from 'src/pages/cupom-de-desconto/__generated__/CupomDeDescontoPageQuery.graphql'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'
import type { DepartmentBannerType } from 'src/typings/sections'

type Props = PageProps<CupomDeDescontoPageQueryQuery>

const Above: FC<Props> = ({ data }) => {
  const introTextSection = useRef<null | HTMLDivElement>(null)

  const pageTitle =
    data.cmsPlLandingPage?.parameters?.plLandingPageParams?.seo?.title

  const pageBanner: DepartmentBannerType =
    data.cmsPlLandingPage?.sections[0].data

  const handleScroll = () => {
    introTextSection.current &&
      window.scrollTo({
        behavior: 'smooth',
        top: introTextSection.current.offsetTop - 100,
      })
  }

  return (
    <div>
      <EventPageView type="OTHER" title={pageTitle} />

      <div style={{ cursor: 'pointer' }} role="button" onClick={handleScroll}>
        <ResponsiveImage
          alt={pageBanner.alt}
          desktop={pageBanner.desktop.srcSet}
          mobile={pageBanner.mobile.srcSet}
        />
      </div>

      <div ref={introTextSection}>
        <IntroductionText />
      </div>

      <Coupons />
    </div>
  )
}

export default Above

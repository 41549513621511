import React, { useState, useEffect } from 'react'
import { Box } from '@vtex/store-ui'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'

import CouponCard from '../CouponCard'
import { mockCoupons } from '../mock'
import styles from './styles.json'

const Coupons = () => {
  const [activeCouponsQty, setActiveCuponsQty] = useState<number>()

  const { isRepresentativeSelected } = useRepresentative()

  const dateNow = new Date(Date.now())
  const actualDay = String(dateNow.getDate()).padStart(2, '0')
  const actualMonth = String(dateNow.getMonth() + 1).padStart(2, '0')

  useEffect(() => {
    if (isRepresentativeSelected) {
      const totalOfCoupons = mockCoupons.filter((coupon) => coupon).length

      setActiveCuponsQty(totalOfCoupons)
    } else {
      const totalOfCoupons = mockCoupons.filter((coupon) =>
        coupon.idsSalesChannel.includes('1')
      ).length

      setActiveCuponsQty(totalOfCoupons)
    }
  }, [isRepresentativeSelected])

  return (
    <div>
      <Box sx={styles.couponWrapper}>
        {activeCouponsQty && (
          <Box sx={styles.couponWrapper.resume}>
            <Box sx={styles.couponWrapper.resume.totalCupons}>
              {activeCouponsQty === 1
                ? `${activeCouponsQty} cupom ativo`
                : `${activeCouponsQty} cupons ativos`}
            </Box>

            <Box sx={styles.couponWrapper.resume.atualizationDate}>
              Atualizado em {actualDay}/{actualMonth}
            </Box>
          </Box>
        )}

        {!isRepresentativeSelected ? (
          <div>
            {mockCoupons
              .filter((utmSource) => utmSource.idsSalesChannel.includes('1'))
              .map((coupon, index) => (
                <CouponCard key={index} coupon={coupon} />
              ))}
          </div>
        ) : (
          <div>
            {mockCoupons.map((coupon, index) => (
              <CouponCard key={index} coupon={coupon} />
            ))}
          </div>
        )}
      </Box>
    </div>
  )
}

export default Coupons

import React, { useState, useEffect } from 'react'
import { Box, Text, Button } from '@vtex/store-ui'

import styles from './styles.json'

const CouponCard = ({ coupon }) => {
  const [showCouponToCopy, setShowCouponToCopy] = useState<boolean>(false)
  const [couponIsCopied, setCouponIsCopied] = useState<boolean>(false)

  const handleShowCouponToCopy = () => {
    setShowCouponToCopy(!showCouponToCopy)
  }

  const handleCopyCoupon = (couponSelected: any) => {
    navigator.clipboard.writeText(couponSelected)
    setShowCouponToCopy(!showCouponToCopy)
    setCouponIsCopied(true)
  }

  return (
    <Box sx={styles}>
      <Box
        sx={
          couponIsCopied ? styles.couponWrapperSelected : styles.couponWrapper
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: ['column', 'row'],
            background: coupon.idsSalesChannel[0] === '2' ? '#FEF5F9' : '',
            padding: '2rem',
            borderRadius: '1.25rem 0',
          }}
        >
          <Box sx={styles.couponWrapper.text}>
            <Text as="h4" sx={styles.couponWrapper.text.couponDescription}>
              {coupon.description}
            </Text>
            {coupon.idsSalesChannel[0] === '1' && (
              <Text as="span" sx={styles.couponWrapper.text.couponName}>
                {coupon.name}
              </Text>
            )}
            {coupon.idsSalesChannel[0] === '2' && (
              <Text as="span" sx={styles.couponWrapper.text.highlight}>
                Válido apenas na loja On-line de Representante Avon
              </Text>
            )}
          </Box>
          <Box sx={styles.couponWrapper.buttonWrapper}>
            {!showCouponToCopy ? (
              <Button type="button" onClick={handleShowCouponToCopy}>
                Pegar desconto
              </Button>
            ) : (
              <Box sx={styles.couponWrapper.buttonCopyWrapper}>
                <Text as="h5"> {coupon.name}</Text>
                <Button
                  type="button"
                  onClick={() => handleCopyCoupon(coupon.utmSource)}
                >
                  <Box as="span" />
                  <Text as="span">Copiar</Text>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CouponCard

export const mockCoupons = [
  {
    idCalculatorConfiguration: 'ebbebf2a-74ef-47d6-aa27-978266c0c5b9',
    lastModifiedUtc: '2023-07-26T03T11:58:35.7713321Z',
    name: 'PRIMEIRA10',
    beginDate: '2021-10-06T03:00:00Z',
    endDate: '2023-12-31T00:00:00Z',
    isActive: true,
    description:
      'GANHE 10% de desconto na sua primeira compra em produtos não promocionais! Válido até 31/12/2023.',
    type: 'regular',
    utmSource: 'PRIMEIRA10',
    utmCampaign: 'PRIMEIRA10',
    utmiCampaign: '',
    status: 'active',
    percentualTax: 0,
    isArchived: false,
    hasMaxPricePerItem: false,
    isTax: false,
    Campaigns: [],
    activateGiftsMultiplier: false,
    effectType: '',
    scope: {
      allCatalog: true,
      skus: 0,
      skusAreInclusive: true,
      products: 0,
      productsAreInclusive: true,
      collections: 0,
      collectionsAreInclusive: true,
      categories: 0,
      categoriesAreInclusive: true,
      brands: 0,
      brandsAreInclusive: true,
      sellers: 0,
      sellersAreInclusive: true,
    },
    maxUsage: 0,
    idsSalesChannel: ['1'],
    areSalesChannelIdsExclusive: false,
  },
  {
    idCalculatorConfiguration: 'ebbebf2a-74ef-47d6-aa27-978266c0c5b9',
    lastModifiedUtc: '2023-07-26T03T11:58:35.7713321Z',
    name: 'AMOKITS5',
    beginDate: '2023-07-26T03:00:00Z',
    endDate: '2023-08-31T00:00:00Z',
    isActive: true,
    description:
      'GANHE 5% de desconto na Categoria de Kits e Presentes em compras acima de R$119,90 e aproveite para presentear quem você ama! Válido até 31/08/2023.',
    type: 'regular',
    utmSource: 'AMOKITS5',
    utmCampaign: 'AMOKITS5',
    utmiCampaign: '',
    status: 'active',
    percentualTax: 0,
    isArchived: false,
    hasMaxPricePerItem: false,
    isTax: false,
    Campaigns: [],
    activateGiftsMultiplier: false,
    effectType: '',
    scope: {
      allCatalog: true,
      skus: 0,
      skusAreInclusive: true,
      products: 0,
      productsAreInclusive: true,
      collections: 0,
      collectionsAreInclusive: true,
      categories: 0,
      categoriesAreInclusive: true,
      brands: 0,
      brandsAreInclusive: true,
      sellers: 0,
      sellersAreInclusive: true,
    },
    maxUsage: 0,
    idsSalesChannel: ['1'],
    areSalesChannelIdsExclusive: false,
  },
  {
    idCalculatorConfiguration: 'ebbebf2a-74ef-47d6-aa27-978266c0c5b9',
    lastModifiedUtc: '2023-07-26T03T11:58:35.7713321Z',
    name: 'AMOCUIDADOS10',
    beginDate: '2023-07-26T03:00:00Z',
    endDate: '2023-08-31T00:00:00Z',
    isActive: true,
    description:
      'GANHE 10% de desconto na Categoria de Rosto e Corpo em compras acima de R$119,90 e aproveite os melhores produtos! Válido até 31/08/2023.',
    type: 'regular',
    utmSource: 'AMOCUIDADOS10',
    utmCampaign: 'AMOCUIDADOS10',
    utmiCampaign: '',
    status: 'active',
    percentualTax: 0,
    isArchived: false,
    hasMaxPricePerItem: false,
    isTax: false,
    Campaigns: [],
    activateGiftsMultiplier: false,
    effectType: '',
    scope: {
      allCatalog: true,
      skus: 0,
      skusAreInclusive: true,
      products: 0,
      productsAreInclusive: true,
      collections: 0,
      collectionsAreInclusive: true,
      categories: 0,
      categoriesAreInclusive: true,
      brands: 0,
      brandsAreInclusive: true,
      sellers: 0,
      sellersAreInclusive: true,
    },
    maxUsage: 0,
    idsSalesChannel: ['1'],
    areSalesChannelIdsExclusive: false,
  },
  {
    idCalculatorConfiguration: 'ebbebf2a-74ef-47d6-aa27-978266c0c5b9',
    lastModifiedUtc: '2023-07-26T03T11:58:35.7713321Z',
    name: 'AMOHOME10',
    beginDate: '2023-07-26T03:00:00Z',
    endDate: '2023-08-31T00:00:00Z',
    isActive: true,
    description:
      'GANHE 10% de desconto na Categoria de Casa e Estilo em compras acima de R$119,90 e encha sua casa de estilo! Válido até 31/08/2023.',
    type: 'regular',
    utmSource: 'AMOHOME10',
    utmCampaign: 'AMOHOME10',
    utmiCampaign: '',
    status: 'active',
    percentualTax: 0,
    isArchived: false,
    hasMaxPricePerItem: false,
    isTax: false,
    Campaigns: [],
    activateGiftsMultiplier: false,
    effectType: '',
    scope: {
      allCatalog: true,
      skus: 0,
      skusAreInclusive: true,
      products: 0,
      productsAreInclusive: true,
      collections: 0,
      collectionsAreInclusive: true,
      categories: 0,
      categoriesAreInclusive: true,
      brands: 0,
      brandsAreInclusive: true,
      sellers: 0,
      sellersAreInclusive: true,
    },
    maxUsage: 0,
    idsSalesChannel: ['1'],
    areSalesChannelIdsExclusive: false,
  },
  {
    idCalculatorConfiguration: 'ebbebf2a-74ef-47d6-aa27-978266c0c5b9',
    lastModifiedUtc: '2023-07-26T03T11:58:35.7713321Z',
    name: 'AMOPERFUME10',
    beginDate: '2023-07-26T03:00:00Z',
    endDate: '2023-08-31T00:00:00Z',
    isActive: true,
    description:
      'GANHE 10% de desconto na Categoria de Perfumaria em compras acima de R$119,90 e aproveite os melhores perfumes! Válido até 31/08/2023.',
    type: 'regular',
    utmSource: 'AMOPERFUME10',
    utmCampaign: 'AMOPERFUME10',
    utmiCampaign: '',
    status: 'active',
    percentualTax: 0,
    isArchived: false,
    hasMaxPricePerItem: false,
    isTax: false,
    Campaigns: [],
    activateGiftsMultiplier: false,
    effectType: '',
    scope: {
      allCatalog: true,
      skus: 0,
      skusAreInclusive: true,
      products: 0,
      productsAreInclusive: true,
      collections: 0,
      collectionsAreInclusive: true,
      categories: 0,
      categoriesAreInclusive: true,
      brands: 0,
      brandsAreInclusive: true,
      sellers: 0,
      sellersAreInclusive: true,
    },
    maxUsage: 0,
    idsSalesChannel: ['1'],
    areSalesChannelIdsExclusive: false,
  },
  {
    idCalculatorConfiguration: 'ebbebf2a-74ef-47d6-aa27-978266c0c5b9',
    lastModifiedUtc: '2023-07-26T03T11:58:35.7713321Z',
    name: 'AMOMAKE10',
    beginDate: '2023-07-26T03:00:00Z',
    endDate: '2023-08-31T00:00:00Z',
    isActive: true,
    description:
      'GANHE 10% de desconto na Categoria de Make em compras acima de R$119,90 e aproveite as melhores maquiagens! Válido até 31/08/2023.',
    type: 'regular',
    utmSource: 'AMOMAKE10',
    utmCampaign: 'AMOMAKE10',
    utmiCampaign: '',
    status: 'active',
    percentualTax: 0,
    isArchived: false,
    hasMaxPricePerItem: false,
    isTax: false,
    Campaigns: [],
    activateGiftsMultiplier: false,
    effectType: '',
    scope: {
      allCatalog: true,
      skus: 0,
      skusAreInclusive: true,
      products: 0,
      productsAreInclusive: true,
      collections: 0,
      collectionsAreInclusive: true,
      categories: 0,
      categoriesAreInclusive: true,
      brands: 0,
      brandsAreInclusive: true,
      sellers: 0,
      sellersAreInclusive: true,
    },
    maxUsage: 0,
    idsSalesChannel: ['1'],
    areSalesChannelIdsExclusive: false,
  },
  {
    idCalculatorConfiguration: '2b4f165c-00c1-4a9e-a934-734dca50649b',
    lastModifiedUtc: '2021-02-02T18:49:15.9581968Z',
    name: 'BV15CONECTA',
    beginDate: '2020-01-02T03:00:00Z',
    endDate: '2023-07-30T02:30:00Z',
    isActive: true,
    description:
      'Nunca comprou na minha loja? Aproveite esse cupom de 15% de desconto! Válido até 30/07/23',
    type: 'regular',
    utmSource: 'BV15CONECTA',
    utmCampaign: 'BV15CONECTA',
    utmiCampaign: '',
    status: 'active',
    percentualTax: 0,
    isArchived: false,
    hasMaxPricePerItem: false,
    isTax: false,
    Campaigns: [],
    activateGiftsMultiplier: false,
    effectType: '',
    scope: {
      allCatalog: true,
      skus: 0,
      skusAreInclusive: true,
      products: 0,
      productsAreInclusive: true,
      collections: 0,
      collectionsAreInclusive: true,
      categories: 0,
      categoriesAreInclusive: true,
      brands: 0,
      brandsAreInclusive: true,
      sellers: 0,
      sellersAreInclusive: true,
    },
    maxUsage: 0,
    idsSalesChannel: ['2'],
    areSalesChannelIdsExclusive: false,
  },
]

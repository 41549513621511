import { styled } from 'src/gatsby-theme-stitches/config'

export const IntroductionTextContainer = styled('div', {
  margin: '0 auto',
  width: '85%',

  h1: {
    fontFamily: 'Zona Pro, sans-serif',
    fontSize: '2.625rem',
    fontWeight: '900',
    color: '#000',

    '@sm': {
      fontSize: '1.5rem',
    },
  },

  p: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '1rem',
    fontWeight: '300',
    color: '#000',
  },
})

export const BreadcrumbContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.375rem',
  marginBottom: '2.6rem',

  '> a': {
    fontFamily: 'Lato, sans-serif',
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: '#000',
  },

  '> svg': {
    display: 'flex',
    fill: '#EF5D9C',
  },
})

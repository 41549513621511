import React from 'react'
import { Link } from 'gatsby'
import { DoubleArrow } from 'src/components/icons'

import { BreadcrumbContainer, IntroductionTextContainer } from './styled'

const IntroductionText = () => {
  return (
    <IntroductionTextContainer>
      <BreadcrumbContainer>
        <Link to="/">Home</Link>
        <DoubleArrow />
        <Link to="/cupom-de-desconto">Cupom de desconto</Link>
      </BreadcrumbContainer>

      <h1>Cupom de desconto</h1>
      <p>Confira os cupons de desconto ativos na Avon.</p>
    </IntroductionTextContainer>
  )
}

export default IntroductionText

import React, { lazy } from 'react'
import type { FC } from 'react'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import { graphql } from 'gatsby'
import Layout from 'src/components/Layout'
import { View } from 'src/components/ui/View'
import Seo from 'src/views/cupom-de-desconto/Seo'
import AboveTheFold from 'src/views/cupom-de-desconto/AboveTheFold'

import type { CupomDeDescontoPageQueryQuery } from './__generated__/CupomDeDescontoPageQuery.graphql'

const loader = () => import('src/views/cupom-de-desconto/BelowTheFold')
const BelowTheFold = lazy(loader)

export type Props = GatsbyPageProps<CupomDeDescontoPageQueryQuery>

const ViewComponents = {
  seo: Seo,
  above: AboveTheFold,
  below: {
    component: BelowTheFold,
    preloader: loader,
  },
}

const Page: FC<Props> = (props) => (
  <Layout>
    <View {...ViewComponents} data={props} />
  </Layout>
)

export const query = graphql`
  query CupomDeDescontoPageQuery {
    cmsPlLandingPage(name: { eq: "Cupom de Desconto" }) {
      name

      parameters {
        plLandingPageParams {
          clusterId

          seo {
            title
            slug
            description
          }
        }
      }

      sections {
        name
        data
      }
    }
  }
`

export default Page
